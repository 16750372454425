import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import ConfirmationDialog from 'components/atoms/ConfirmationDialog'
import SplitSelect from 'components/atoms/SplitSelect'
import AdminPageWrapper from 'components/molecules/AdminPageWrapper'
import ConverterHaulerPanelWrapper from 'components/molecules/ConverterHaulerPanelWrapper'
import Loading from 'components/molecules/Loading'
import { converterStatus, converterType, states } from 'data'
import { useAtom } from 'jotai'
import { differenceBy, differenceWith, isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { alertAtom } from 'stores'
import { accessTokenAtom, getDecodedAccessToken } from 'stores/auth'
import {
  addHaulerSettings,
  getHaulerSettingByvendor,
  updateHaulerSettings,
} from 'stores/hauler'
import {
  deleteMissingServiceAccLUById,
  deleteMissingServiceAccount,
  getMissingServiceAccLUByvendor,
  getVendorDetails,
  upsertVendor,
} from 'stores/vendor'
import theme from 'theme'
import { HaulerSetting } from 'types/Hauler'
import { MissingServiceAccLUType, VendorType } from 'types/Vendor'

const regex = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  postalCode: /(^(?!0{5})(\d{5})(?!-?0{4})(|-\d{4})?$)/,
  address: /^[a-zA-Z0-9\s,'-.]*$/,
  city: /^(?:[A-Za-z]{2,}(?:(\.\s|'s\s|\s?-\s?|\s)?(?=[A-Za-z]+))){1,2}(?:[A-Za-z]+)?$/,
  name: /^[a-zA-Z& ]{2,30}$/,
  vendorName: /^[a-zA-Z0-9&_]{2,100}$/,
  longName: /^[a-zA-Z0-9,&()':;.\-"$@/ ]{2,100}$/,
}

const Details = (): JSX.Element => {
  const [accessToken] = useAtom(accessTokenAtom)
  const permissions = getDecodedAccessToken()?.permissions
  const newVendor: VendorType = {
    name: '',
    longName: '',
    paymentAddress: {
      recipient: '',
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
    },
    physicalAddress: {
      recipient: '',
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
    },
    email: '',
    phone: '',
    converterStatus: 0,
    converterType: 1,
    serviceAccountLookups: [],
  }
  const [, setAlert] = useAtom(alertAtom)
  const { name } = useParams()
  const [vendorDetails, setVendorDetails] = useState<VendorType>(newVendor)
  const [vendorDetailsLoading, setVendorDetailsLoading] = useState(false)
  const [isSavingVendor, setIsSavingVendor] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [serviceAccountLookupDeleteIndex, setServiceAccountLookupDeleteIndex] =
    useState<number | null>(null)
  const [missingServiceAccLULoading, setMissingServiceAccLULoading] =
    useState(false)
  const [missingServiceAccLU, setMissingServiceAccLU] = useState<
    MissingServiceAccLUType[] | []
  >([])

  const [actualMissingServiceAccLU, setActualMissingServiceAccLU] = useState<
    MissingServiceAccLUType[] | []
  >([])

  const [haulerSettingsSaving, setHaulerSettingsSaving] =
    useState<boolean>(false)
  const [haulerSettingsLoading, setHaulerSettingsLoading] =
    useState<boolean>(true)
  const [haulerSetting, setHaulerSetting] = useState<HaulerSetting | null>(null)

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors, isDirty },
  } = useForm<VendorType>({
    defaultValues: vendorDetails,
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'serviceAccountLookups',
  })

  const watchFieldArray = watch('serviceAccountLookups')
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    }
  })

  const getDetails = (showLoading = true): void => {
    if (name) {
      // existing vendor
      if (showLoading) {
        setVendorDetailsLoading(true)
      }
      setMissingServiceAccLULoading(true)
      getVendorDetails(name)
        .then(async response => {
          const resp = response.data
          setVendorDetails(resp)
          reset(resp)
          setVendorDetailsLoading(false)

          getMissingServiceAccLUByvendor(name)
            .then(missingServiceAccLUResp => {
              if (missingServiceAccLUResp.data) {
                // Only pick the missing service account if the lookup string
                // already doesnt exists in the existing service accounts
                const filteredMissingServiceAccounts = differenceBy(
                  missingServiceAccLUResp.data,
                  resp.serviceAccountLookups,
                  'lookupString'
                )

                const data = filteredMissingServiceAccounts.map(data => ({
                  ...data,
                  text: data.lookupString,
                  value: data.id,
                }))

                setActualMissingServiceAccLU(data)
                setMissingServiceAccLU(data)
                setMissingServiceAccLULoading(false)
              }
            })
            .catch(() => {
              setMissingServiceAccLULoading(false)
              setAlert({
                show: true,
                type: 'error',
                message: 'Failed to load missing service account lookups',
                autoHideDuration: 2000,
              })
            })
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to load vendor details',
            autoHideDuration: 2000,
          })
          setMissingServiceAccLULoading(false)
          setVendorDetailsLoading(false)
        })

      getHaulerSettings(name)
    } else {
      // create new vendor
      setVendorDetails(newVendor)
    }
  }

  const getHaulerSettings = (selectedVendor: string): void => {
    setHaulerSettingsLoading(true)
    setHaulerSetting(null)

    // HaulerSettings
    getHaulerSettingByvendor(selectedVendor)
      .then(response => {
        if (response.data) {
          const data = response.data
          if (data.duplicateCheckOverride === null) {
            data.duplicateCheckOverride = false
          }
          setHaulerSetting(data)
        }
        setHaulerSettingsLoading(false)
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to get hauler settings',
          autoHideDuration: 2000,
        })
        setHaulerSettingsLoading(false)
      })
  }

  useEffect(() => {
    if (accessToken) {
      getDetails()
    }
  }, [accessToken])

  const onSubmit: SubmitHandler<VendorType> = formData => {
    setIsSavingVendor(true)
    if (formData?.id) {
      // update existing vendor
      upsertVendor(formData)
        .then(async () => {
          setIsSavingVendor(false)
          setVendorDetails(formData)

          if (actualMissingServiceAccLU) {
            const consumedMissingSerAccs = differenceWith(
              actualMissingServiceAccLU,
              missingServiceAccLU,
              isEqual
            )

            await Promise.all(
              consumedMissingSerAccs.map(async consumedMissingSerAcc => {
                await deleteMissingServiceAccount(
                  consumedMissingSerAcc.id
                ).catch(() => {
                  setAlert({
                    show: true,
                    type: 'error',
                    message: `Failed to delete missing account: ${consumedMissingSerAcc.lookupString}`,
                    autoHideDuration: 2000,
                  })
                })
              })
            ).then(async () => {
              if (name) {
                // TODO: redundant code needs clean up
                await getMissingServiceAccLUByvendor(name)
                  .then(missingServiceAccLUResp => {
                    if (missingServiceAccLUResp.data) {
                      const filteredMissingServiceAccounts = differenceBy(
                        missingServiceAccLUResp.data,
                        vendorDetails.serviceAccountLookups,
                        'lookupString'
                      )

                      const data = filteredMissingServiceAccounts.map(data => ({
                        ...data,
                        text: data.lookupString,
                        value: data.id,
                      }))

                      setActualMissingServiceAccLU(data)
                      setMissingServiceAccLU(data)
                    }
                  })
                  .catch(() => {
                    setAlert({
                      show: true,
                      type: 'error',
                      message: 'Failed to load missing service account lookups',
                      autoHideDuration: 2000,
                    })
                  })
              }
            })
          }

          reset(formData)
          setAlert({
            show: true,
            type: 'success',
            message: 'Updated vendor details',
            autoHideDuration: 2000,
          })
        })
        .catch(() => {
          setIsSavingVendor(false)
          reset()
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to update vendor details',
            autoHideDuration: 2000,
          })
        })
    } else {
      // add new vendor
      upsertVendor(formData)
        .then(response => {
          if (response.data && typeof response.data === 'string') {
            setVendorDetails(newVendor)
            reset(newVendor)
            setIsSavingVendor(false)
            setAlert({
              show: true,
              type: 'success',
              message: 'Created new vendor',
              autoHideDuration: 2000,
            })
          }
        })
        .catch(() => {
          setIsSavingVendor(false)
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to crete new vendor',
            autoHideDuration: 2000,
          })
        })
    }
  }

  const handleHaulerSettingsSubmit = (formData: HaulerSetting): void => {
    setHaulerSettingsSaving(true)
    if (haulerSetting) {
      updateHaulerSettings(formData)
        .then(response => {
          // Update local vendor state on success
          setHaulerSetting({ ...formData, timestamp: `${response.data?._ts}` })
          setAlert({
            show: true,
            type: 'success',
            message: 'Updated hauler settings',
            autoHideDuration: 2000,
          })
          setHaulerSettingsSaving(false)
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to update hauler settings',
            autoHideDuration: 2000,
          })
          setHaulerSettingsSaving(false)
        })
    } else {
      addHaulerSettings(formData)
        .then(() => {
          // Update local vendor state on success
          setHaulerSetting(formData)
          setAlert({
            show: true,
            type: 'success',
            message: 'Added hauler settings',
            autoHideDuration: 2000,
          })
          setHaulerSettingsSaving(false)
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to add hauler settings',
            autoHideDuration: 2000,
          })
          setHaulerSettingsSaving(false)
        })
    }
  }

  const handleServiceAccountLookupDelete = (index: number): void => {
    setConfirmDialogOpen(true)
    setServiceAccountLookupDeleteIndex(index)
  }

  const handleDeleteClose = (): void => {
    setConfirmDialogOpen(false)
    setServiceAccountLookupDeleteIndex(null)
  }

  const handleDeleteConfirm = (): void => {
    if (serviceAccountLookupDeleteIndex !== null) {
      remove(serviceAccountLookupDeleteIndex)
      setServiceAccountLookupDeleteIndex(null)

      const foundMissingLookups = actualMissingServiceAccLU.find(
        missingServiceAccLUItem =>
          missingServiceAccLUItem.lookupString ===
          controlledFields[serviceAccountLookupDeleteIndex].lookupString
      )

      if (foundMissingLookups) {
        setMissingServiceAccLU(prevMissingServiceACCLU => [
          ...prevMissingServiceACCLU,
          ...[foundMissingLookups],
        ])
      }
    }
    setConfirmDialogOpen(false)
  }

  const handleMissingServiceAccLUSelect = (
    option: MissingServiceAccLUType | null
  ): void => {
    if (option) {
      append({
        lookupString: option.lookupString,
        serviceAccountNumber: '',
      })

      const filteredMissingLookups = missingServiceAccLU.filter(
        missingServiceAccLUItem =>
          missingServiceAccLUItem.lookupString !== option.lookupString
      )

      setMissingServiceAccLU(filteredMissingLookups)
    }
  }

  const handleMissingLookupDeleteClick = async (
    event: React.MouseEvent,
    option: any
  ): Promise<void> => {
    event.stopPropagation()
    await deleteMissingServiceAccLUById(option.id)
      .then(() => {
        getDetails(false)
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: `Failed to delete missing account lookup string: ${option.lookupString}`,
          autoHideDuration: 2000,
        })
      })
  }

  const handleMoreinfoClick = (event: React.MouseEvent, option: any): void => {
    event.stopPropagation()
    const newWindow = window.open(
      option.fileUrl,
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) {
      newWindow.opener = null
    }
  }

  return (
    <AdminPageWrapper
      pageTitle={name ? 'Edit Vendor Details' : 'Add New Vendor'}
    >
      <ConfirmationDialog
        open={confirmDialogOpen}
        description="Are you sure you want to delete this service account ?"
        icon="highlight_off"
        action={
          <>
            <Button
              autoFocus
              color="primary"
              variant="contained"
              onClick={handleDeleteClose}
              disableElevation
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              color="warning"
              autoFocus
              variant="contained"
              onClick={handleDeleteConfirm}
              disableElevation
            >
              Delete
            </Button>
          </>
        }
      />
      <Card data-testid="vendor-container" sx={{ mb: 2 }}>
        <CardContent sx={{ my: 2 }}>
          {!vendorDetailsLoading && vendorDetails ? (
            <form>
              <fieldset
                disabled={isSavingVendor}
                style={{ border: 0, padding: 0 }}
              >
                <input
                  {...register('id')}
                  value={vendorDetails.id}
                  type="hidden"
                />
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      label="Name"
                      size="small"
                      InputProps={{
                        readOnly: name ? true : false,
                      }}
                      {...register('name', {
                        required: 'Name is Required ',
                        pattern: {
                          value: regex.vendorName,
                          message: 'Invalid Name.',
                        },
                      })}
                      defaultValue={vendorDetails.name ?? ''}
                      placeholder="Name"
                      error={errors?.name ? true : false}
                      helperText={
                        errors?.name ? String(errors.name.message) : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      label="Long Name"
                      size="small"
                      {...register('longName', {
                        required: 'Long Name is Required ',
                      })}
                      defaultValue={vendorDetails.longName ?? ''}
                      placeholder="Long Name"
                      error={errors?.longName ? true : false}
                      helperText={
                        errors?.longName ? String(errors.longName.message) : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel id="paymentAddress-group" sx={{ mb: 1 }}>
                        Payment Address
                      </FormLabel>
                      <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Recipient"
                            size="small"
                            {...register('paymentAddress.recipient')}
                            defaultValue={
                              vendorDetails.paymentAddress.recipient ?? ''
                            }
                            placeholder="Recipient"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Street Address"
                            size="small"
                            {...register('paymentAddress.streetAddress', {
                              pattern: {
                                value: regex.address,
                                message: 'Invalid Address.',
                              },
                            })}
                            defaultValue={
                              vendorDetails.paymentAddress.streetAddress ?? ''
                            }
                            placeholder="Street Address"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="City"
                            size="small"
                            type="text"
                            {...register('paymentAddress.city', {
                              pattern: {
                                value: regex.city,
                                message: 'Invalid City.',
                              },
                            })}
                            defaultValue={
                              vendorDetails.paymentAddress.city ?? ''
                            }
                            placeholder="City"
                            error={errors?.paymentAddress?.city ? true : false}
                            helperText={
                              errors?.paymentAddress?.city
                                ? String(errors.paymentAddress.city.message)
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="State"
                            size="small"
                            select
                            {...register('paymentAddress.state')}
                            defaultValue={
                              vendorDetails.paymentAddress.state ?? ''
                            }
                            placeholder="State"
                          >
                            {states.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Postal Code"
                            type="text"
                            {...register('paymentAddress.postalCode', {
                              pattern: {
                                value: regex.postalCode,
                                message: 'Invalid Postal Code.',
                              },
                            })}
                            defaultValue={
                              vendorDetails.paymentAddress.postalCode ?? ''
                            }
                            placeholder="XXXXX-XXXX"
                            error={
                              errors?.paymentAddress?.postalCode ? true : false
                            }
                            helperText={
                              errors?.paymentAddress?.postalCode
                                ? String(
                                    errors.paymentAddress?.postalCode.message
                                  )
                                : ''
                            }
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel id="physicalAddress-group" sx={{ mb: 1 }}>
                        Physical Address
                      </FormLabel>
                      <Grid
                        container
                        rowSpacing={3}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Recipient"
                            size="small"
                            {...register('physicalAddress.recipient')}
                            defaultValue={
                              vendorDetails.physicalAddress.recipient ?? ''
                            }
                            placeholder="Recipient"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Street Address"
                            size="small"
                            {...register('physicalAddress.streetAddress', {
                              pattern: {
                                value: regex.address,
                                message: 'Invalid Address.',
                              },
                            })}
                            defaultValue={
                              vendorDetails.physicalAddress.streetAddress ?? ''
                            }
                            placeholder="Street Address"
                            error={
                              errors?.physicalAddress?.streetAddress
                                ? true
                                : false
                            }
                            helperText={
                              errors?.physicalAddress?.streetAddress
                                ? String(errors.physicalAddress.message)
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="City"
                            size="small"
                            type="text"
                            {...register('physicalAddress.city', {
                              pattern: {
                                value: regex.city,
                                message: 'Invalid City.',
                              },
                            })}
                            defaultValue={
                              vendorDetails.physicalAddress.city ?? ''
                            }
                            placeholder="City"
                            error={errors?.physicalAddress?.city ? true : false}
                            helperText={
                              errors?.physicalAddress?.city
                                ? String(errors.physicalAddress.city.message)
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="State"
                            size="small"
                            select
                            {...register('physicalAddress.state')}
                            defaultValue={
                              vendorDetails.physicalAddress.state ?? ''
                            }
                            placeholder="State"
                          >
                            {states.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Postal Code"
                            type="text"
                            {...register('physicalAddress.postalCode', {
                              pattern: {
                                value: regex.postalCode,
                                message: 'Invalid Postal Code.',
                              },
                            })}
                            defaultValue={
                              vendorDetails.physicalAddress.postalCode ?? ''
                            }
                            placeholder="XXXXX-XXXX"
                            error={
                              errors?.physicalAddress?.postalCode ? true : false
                            }
                            helperText={
                              errors?.physicalAddress?.postalCode
                                ? String(
                                    errors.physicalAddress?.postalCode.message
                                  )
                                : ''
                            }
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      size="small"
                      {...register('email', {
                        pattern: {
                          value: regex.email,
                          message: 'Invalid Email.',
                        },
                      })}
                      defaultValue={vendorDetails.email ?? ''}
                      placeholder="Email"
                      error={errors?.email ? true : false}
                      helperText={
                        errors?.email ? String(errors.email.message) : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: '100%',
                      }}
                    >
                      <Box
                        id="serviceAccountLookups-group"
                        sx={{
                          mb: 1,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          background: theme.palette.secondary.light,
                          px: 1,
                          py: 1,
                        }}
                      >
                        <Typography
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          Service Account Lookups
                          {controlledFields.length ? (
                            <Tooltip
                              title={
                                (controlledFields.length
                                  ? `There are ${controlledFields.length} service account lookups`
                                  : '') +
                                (missingServiceAccLU.length
                                  ? ` and ${missingServiceAccLU.length} missing service account lookups`
                                  : '')
                              }
                              placement="right"
                              arrow
                            >
                              <InfoOutlinedIcon
                                color="info"
                                sx={{
                                  fontSize: '1.04rem',
                                  ml: 1,
                                  cursor: 'pointer',
                                }}
                              />
                            </Tooltip>
                          ) : null}
                        </Typography>

                        <Box sx={{ display: 'flex' }}>
                          {controlledFields.length &&
                          permissions?.includes('insert:serviceAccounts') ? (
                            <Button
                              variant="outlined"
                              startIcon={<AddIcon />}
                              onClick={(): void => {
                                append({
                                  lookupString: '',
                                  serviceAccountNumber: '',
                                })
                              }}
                              size="small"
                              sx={{ mr: missingServiceAccLU.length ? 1 : 0 }}
                            >
                              Add new
                            </Button>
                          ) : null}
                          {missingServiceAccLU.length ? (
                            <SplitSelect
                              placeHolder="Select missing service account"
                              loading={missingServiceAccLULoading}
                              options={missingServiceAccLU}
                              onSelect={handleMissingServiceAccLUSelect}
                              onMoreinfo={handleMoreinfoClick}
                              onDelete={handleMissingLookupDeleteClick}
                            />
                          ) : null}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          maxHeight: '17rem',
                          overflow: 'auto',
                          border: '1px solid rgba(0, 0, 0, 0)',
                          borderRadius: '0.2rem',
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            my: 0,
                            pt: 0,
                            pb: 1,
                            px: 2,
                          }}
                        >
                          {controlledFields.length ? (
                            controlledFields.map((field, index) => (
                              <Grid
                                key={index}
                                container
                                item
                                columnSpacing={1}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <TextField
                                    fullWidth
                                    label="Lookup String"
                                    size="small"
                                    required
                                    inputProps={{
                                      readOnly: !permissions?.includes(
                                        'update:serviceAccounts'
                                      ),
                                    }}
                                    InputProps={{
                                      className: !permissions?.includes(
                                        'update:serviceAccounts'
                                      )
                                        ? 'Mui-disabled'
                                        : undefined,
                                    }}
                                    {...register(
                                      `serviceAccountLookups.${index}.lookupString`,
                                      {
                                        required:
                                          'Lookup string can not be empty.',
                                      }
                                    )}
                                    defaultValue={field.lookupString ?? ''}
                                    placeholder="Lookup String"
                                    error={
                                      errors?.serviceAccountLookups?.[index]
                                        ?.lookupString
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors?.serviceAccountLookups?.[index]
                                        ?.lookupString
                                        ? String(
                                            errors.serviceAccountLookups[index]
                                              ?.lookupString?.message
                                          )
                                        : ''
                                    }
                                  ></TextField>
                                </Grid>
                                <Grid item xs={5.7}>
                                  <TextField
                                    fullWidth
                                    label="Service Account Number"
                                    size="small"
                                    required
                                    inputProps={{
                                      readOnly: !permissions?.includes(
                                        'update:serviceAccounts'
                                      ),
                                    }}
                                    InputProps={{
                                      className: !permissions?.includes(
                                        'update:serviceAccounts'
                                      )
                                        ? 'Mui-disabled'
                                        : undefined,
                                    }}
                                    {...register(
                                      `serviceAccountLookups.${index}.serviceAccountNumber`,
                                      {
                                        required:
                                          'Service account number can not be empty.',
                                      }
                                    )}
                                    defaultValue={
                                      field.serviceAccountNumber ?? ''
                                    }
                                    placeholder="Service Account Number"
                                    error={
                                      errors?.serviceAccountLookups?.[index]
                                        ?.serviceAccountNumber
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      errors?.serviceAccountLookups?.[index]
                                        ?.serviceAccountNumber
                                        ? String(
                                            errors.serviceAccountLookups[index]
                                              ?.serviceAccountNumber?.message
                                          )
                                        : ''
                                    }
                                  />
                                </Grid>
                                {permissions?.includes(
                                  'delete:serviceAccounts'
                                ) ? (
                                  <Grid item xs={0.2}>
                                    <IconButton
                                      color="error"
                                      onClick={(): void =>
                                        handleServiceAccountLookupDelete(index)
                                      }
                                      disableFocusRipple
                                      size="small"
                                    >
                                      <Tooltip
                                        title="Delete"
                                        placement="top"
                                        arrow
                                      >
                                        <DeleteIcon fontSize="inherit" />
                                      </Tooltip>
                                    </IconButton>
                                  </Grid>
                                ) : null}
                              </Grid>
                            ))
                          ) : permissions?.includes(
                              'insert:serviceAccounts'
                            ) ? (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <Typography>
                                No Service Account Lookups found.
                              </Typography>
                              <Button
                                variant="text"
                                onClick={(): void =>
                                  append({
                                    lookupString: '',
                                    serviceAccountNumber: '',
                                  })
                                }
                                size="small"
                                sx={{
                                  textTransform: 'none',
                                  fontSize: '0.875rem',
                                }}
                              >
                                Add New ?
                              </Button>
                            </Box>
                          ) : null}
                        </Grid>
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      label="Converter Status"
                      size="small"
                      select
                      {...register('converterStatus', {
                        required: 'Converter Status is Required.',
                      })}
                      defaultValue={vendorDetails.converterStatus}
                      placeholder="Converter Status"
                      error={errors?.converterStatus ? true : false}
                      helperText={
                        errors?.converterStatus
                          ? String(errors.converterStatus.message)
                          : ''
                      }
                    >
                      {converterStatus.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      label="Converter Type"
                      size="small"
                      select
                      {...register('converterType', {
                        required: 'Converter Type is Required.',
                      })}
                      defaultValue={vendorDetails.converterType}
                      placeholder="Converter Type"
                      error={errors?.converterType ? true : false}
                      helperText={
                        errors?.converterType
                          ? String(errors.converterType.message)
                          : ''
                      }
                    >
                      {converterType.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color="error"
                      variant="small"
                      component="div"
                      sx={{ textAlign: 'right' }}
                    >
                      {!permissions?.includes('update:serviceAccounts') ||
                      !permissions?.includes('insert:serviceAccounts')
                        ? '*You dont have permission to add/update vendors. Contact the administrator to obtain permissions.'
                        : ''}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'end' }}
                  >
                    <LoadingButton
                      loading={isSavingVendor}
                      variant="contained"
                      onClick={handleSubmit(onSubmit)}
                      disabled={
                        isSavingVendor ||
                        !isDirty ||
                        (name
                          ? !permissions?.includes('update:serviceAccounts')
                          : !permissions?.includes('insert:serviceAccounts'))
                      }
                      loadingIndicator={name ? 'Updating...' : 'Saving...'}
                      size="small"
                      sx={{
                        width: isSavingVendor ? '5.625rem' : '4.375rem',
                        mr: 2,
                      }}
                    >
                      {name ? 'Update' : 'Add'}
                    </LoadingButton>
                    <Button
                      variant="contained"
                      disabled={isSavingVendor || !isDirty}
                      onClick={(): void => {
                        reset(vendorDetails)
                        setMissingServiceAccLU(actualMissingServiceAccLU)
                      }}
                      size="small"
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </fieldset>
            </form>
          ) : vendorDetailsLoading ? (
            <Loading message="Loading vendor details..." height="86vh" />
          ) : (
            <Box sx={{ minHeight: '5rem', p: 2 }}>
              <Typography>No details found for selected vendor.</Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      {!haulerSettingsLoading && name ? (
        <ConverterHaulerPanelWrapper
          haulerSetting={haulerSetting}
          saving={haulerSettingsSaving}
          canAddOrUpdate={permissions?.includes('update:hauler')}
          onSubmit={handleHaulerSettingsSubmit}
        />
      ) : null}
    </AdminPageWrapper>
  )
}

export default Details
