// import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { Box, Typography } from '@mui/material'
import { PrimitiveAtom, useAtom } from 'jotai'
import { focusAtom } from 'jotai/optics'
import { splitAtom } from 'jotai/utils'
import { focusGroupedSites } from 'stores/productMappings'
import {
  LookUpData,
  ProductItemsType,
  ProductLookupStringsType,
  ProductMappingsType,
} from 'types/ProductMappings'

import Site from './Site'

const Sites = ({
  setIsDirty,
  groupedSitesAtom,
  lookupMappingList,
  productItemsList,
  viewHiddenLookups,
  onAllLookUpsFNOItemsReplace,
  onAllLookUpsPatternReplace,
}: {
  setIsDirty: (isDirty: boolean) => void
  groupedSitesAtom: PrimitiveAtom<ProductMappingsType['groupedSites'][number]>
  lookupMappingList: ProductLookupStringsType[]
  productItemsList: ProductItemsType[]
  viewHiddenLookups: boolean
  onAllLookUpsFNOItemsReplace: (
    lookup: LookUpData,
    productItem: ProductItemsType | null
  ) => void
  onAllLookUpsPatternReplace: (
    lookup: LookUpData,
    pattern: string | null
  ) => void
}): JSX.Element => {
  const [groupedAccount, setGroupedAccount] = useAtom(groupedSitesAtom)

  const groupedSiteAtom = focusAtom(groupedSitesAtom, focusGroupedSites)
  const sitesAtomsAtom = splitAtom(groupedSiteAtom)
  const [sitesAtoms, setSitesAtoms] = useAtom(sitesAtomsAtom)

  const isMissingBillingAccount = !groupedAccount.billingAccount.length

  const handleSiteDelete = (
    siteAtom: PrimitiveAtom<ProductMappingsType['sites'][number]>
  ): void => {
    setSitesAtoms({
      type: 'remove',
      atom: siteAtom,
    })
    setIsDirty(true)
  }

  // const handleSiteAdd = (): void => {
  //   setSitesAtoms({
  //     type: 'insert',
  //     value: {
  //       siteName: 'New Site (CHANGE)',
  //       serviceAccountNumber: '',
  //       lookUpData: [],
  //       billingAccount: groupedAccount.billingAccount,
  //       billingAccountNumber: '',
  //     },
  //   })
  //   setIsDirty(true)
  // }

  const handleSiteDuplicate = (
    index: number,
    site: ProductMappingsType['sites'][number]
  ): void => {
    setSitesAtoms({
      type: 'insert',
      value: {
        ...site,
        siteName: `${site.siteName} (COPY)`,
      },
    })

    setIsDirty(true)
  }

  return (
    <Box className="site-wrapper" sx={{ mb: 4 }}>
      <Box
        className="site-wrapper--header"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid rgba(0, 0, 0, .125)',
          mb: 1,
        }}
      >
        <Typography
          contentEditable="false" // {!!groupedAccount.billingAccount.length}
          suppressContentEditableWarning
          placeholder="Missing Billing Accounts"
          onClick={(event): void => {
            event.stopPropagation()
          }}
          onBlur={(event): void => {
            if (
              event.currentTarget.textContent !== groupedAccount.billingAccount
            ) {
              setGroupedAccount(prevAccount => ({
                ...prevAccount,
                billingAccount: event.currentTarget.textContent || '',
                sites: prevAccount.sites.map(site => ({
                  ...site,
                  billingAccount: event.currentTarget.textContent || '',
                })),
              }))
              setIsDirty(true)
            }
          }}
          onPaste={(event): void => {
            const data = event.clipboardData.getData('text/plain')
            document.execCommand('insertHTML', false, data)
            event.preventDefault()
          }}
          sx={{
            mr: 4,
            p: 1,
            minWidth: '12.5rem',
            '&:focus': {
              outline: '1px solid #008F6B',
              borderRadius: '.25rem',
            },
            '&:empty:before': {
              color: '#EF314E',
              content: 'attr(placeholder)',
              pointerEvents: 'none',
              display: 'block',
            },
          }}
        >
          {groupedAccount.billingAccount}
        </Typography>
        {/* {!isMissingBillingAccount ? (
          <Tooltip
            title={`Click to add service account under ${groupedAccount.billingAccount}`}
            placement="left"
            arrow
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={<PlaylistAddIcon />}
              onClick={handleSiteAdd}
              sx={{ mb: 0.5 }}
            >
              site
            </Button>
          </Tooltip>
        ) : null} */}
      </Box>
      {sitesAtoms.map((siteAtom, index) => (
        <Site
          key={`${siteAtom}`}
          index={index}
          siteAtom={siteAtom}
          setIsDirty={setIsDirty}
          isMissingBillingAccount={isMissingBillingAccount}
          lookupMappingList={lookupMappingList}
          productItemsList={productItemsList}
          viewHiddenLookups={viewHiddenLookups}
          onSiteDelete={handleSiteDelete}
          onSiteDuplicate={handleSiteDuplicate}
          onAllLookUpsFNOItemsReplace={onAllLookUpsFNOItemsReplace}
          onAllLookUpsPatternReplace={onAllLookUpsPatternReplace}
        />
      ))}
    </Box>
  )
}

export default Sites
