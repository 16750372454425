import { Box, Typography } from '@mui/material'
import Sites from 'components/molecules/Sites'
import { PrimitiveAtom } from 'jotai'
import {
  LookUpData,
  ProductItemsType,
  ProductLookupStringsType,
  ProductMappingsType,
} from 'types/ProductMappings'

const Mappings = ({
  setIsDirty,
  groupedSitesAtoms,
  lookupMappingList,
  productItemsList,
  viewHiddenLookups,
  onAllLookUpsFNOItemsReplace,
  onAllLookUpsPatternReplace,
}: {
  setIsDirty: (isDirty: boolean) => void
  groupedSitesAtoms: PrimitiveAtom<
    ProductMappingsType['groupedSites'][number]
  >[]
  lookupMappingList: ProductLookupStringsType[]
  productItemsList: ProductItemsType[]
  viewHiddenLookups: boolean
  onAllLookUpsFNOItemsReplace: (
    lookup: LookUpData,
    productItem: ProductItemsType | null
  ) => void
  onAllLookUpsPatternReplace: (
    lookup: LookUpData,
    pattern: string | null
  ) => void
}): JSX.Element => {
  return groupedSitesAtoms && groupedSitesAtoms.length ? (
    <Box
      className="mappings-wrapper"
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      {groupedSitesAtoms.map(groupedSitesAtom => {
        return (
          <Sites
            key={`${groupedSitesAtom}`}
            setIsDirty={setIsDirty}
            groupedSitesAtom={groupedSitesAtom}
            lookupMappingList={lookupMappingList}
            productItemsList={productItemsList}
            viewHiddenLookups={viewHiddenLookups}
            onAllLookUpsFNOItemsReplace={onAllLookUpsFNOItemsReplace}
            onAllLookUpsPatternReplace={onAllLookUpsPatternReplace}
          />
        )
      })}
    </Box>
  ) : (
    <Typography sx={{ p: 2 }}>No Billing Accounts Found</Typography>
  )
}

export default Mappings
